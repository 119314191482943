/*! {!project_name!} v0.3.3 */
/**
 * JS for: {!project_name!}
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 *
 * Modules
 * jQuery document ready
 * 
 * @todo Add each module/section needed
 * 
 */
const Hypher = require('hypher');
var spanish = require('hyphenation.es');
var hyphen = new Hypher(spanish);
/**
 * Ajax submit
 */
function form_ajax_submit()
{
  notification_message = 'Ocurrio un error al enviar tu mensaje.';
  notification_class = 'error';
  notification_check = false;
  $.ajax({
        type: 'POST',
        url: 'index.php',
        data: $('#form-contact').serializeArray(),
        dataType: 'json',
        success: function(data)
        {
          if (data.status) {
            //console.log(data);
            notification_message = 'Tu mensaje ha sido enviado.';
            notification_class = 'success';
            $('#form-contact')[0].reset();
          } else {
            //console.log(data);
            switch(data.result_code) {
              case 1:
                notification_message = 'Verifica que hayas llenado todos los campos correctamente.';
                notification_class = 'warning';
                break;
              case 2:
                notification_message = 'Ocurrio un errol al verificar el reCaptcha.';
                notification_class = 'warning';
                break;
              default:
              }
          }
          $('.notification').addClass(notification_class);
          $('.notification-message').html(notification_message);
          $('#check-notification-slide').prop('checked', notification_check);
        },
        error: function(data)
        {
          //console.log(data);
          $('.notification').addClass(notification_class);
          $('.notification-message').html(notification_message);
          $('#check-notification-slide').prop('checked', notification_check);
        }
      });
}
/* jQuery document ready */
$(document).ready(function()
{
	/* ref: https://stackoverflow.com/a/32491636 */
  	document.querySelector('form').setAttribute('action', '');
	/* Form submit */
  	$('#form-contact').on('submit', function(e)
  	{
    	form_ajax_submit();
    	e.preventDefault();
  	});
	var text = $('.about-text').html();
	$('.about-text').html(hyphen.hyphenateText(text));

	$('a.main.catalog').on('click', function(e)
	{
		$('#nosotros').fadeOut('fast', function()
		{
			$('#contacto, #showroom').fadeIn('slow');
			$('html, body').animate({ scrollTop: 0 }, 'slow');
		});
		e.preventDefault();
	});
	$('a.back').on('click', function(e)
	{
		$('#contacto, #showroom').fadeOut('fast', function()
		{
			$('#nosotros').fadeIn('slow');
			$('html, body').animate({ scrollTop: 0 }, 'slow');
		});
		e.preventDefault();
	});
});
